<template>
    <div>
        <b-row>
            <b-col cols="6">
                <zw-select-group v-model="inputVal.attribute"
                                 :options="attributesFiltered | optionsVV"
                                 name="attribute"
                                 :label-prefix="labelPrefix"
                                 validate="required"
                ></zw-select-group>
            </b-col>
            <b-col cols="6">
                <component
                    v-if="Object.keys(fields).includes(type) && Object.keys(fields[type]).includes(inputVal.attribute)"
                    v-bind:is="fields[type][inputVal.attribute]"
                    v-model="inputVal.value"
                    label-prefix="article.label.change_attribute."
                    :old-value="inputVal.old_value"
                    :ids="inputVal.ids"
                    @input-old="oldValueChanged"
                ></component>
                <template v-else-if="inputVal.attribute && inputVal.attribute.startsWith('custom.')">
                    <b-form-group v-if="Object.keys(getCustomFieldOptions(inputVal.attribute)).length">
                        <template v-slot:label>
                            {{$t(labelPrefix+'value')}}
                        </template>
                        <multiselect v-model="inputVal.value"
                                     :searchable="true"
                                     :options="Object.keys(getCustomFieldOptions(inputVal.attribute))"
                                     :custom-label="opt => getCustomFieldOptions(inputVal.attribute)[opt]"
                                     select-label=""
                        ></multiselect>
                    </b-form-group>
                    <zw-input-group v-else
                                    v-model="inputVal.value"
                                    name="value"
                                    :label-prefix="labelPrefix"
                    ></zw-input-group>
                </template>
                <template v-else>
                    <zw-input-group v-model="inputVal.value"
                                    name="value"
                                    :label-prefix="labelPrefix"
                    ></zw-input-group>
                </template>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'ChangeAttribute',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
            type: null,
            attributesFiltered: null,
            fields: {
                'offering': {
                    'shipping': () => import('/src/modals/massupdate/offering_attributes/shipping'),
                    'status': () => import('/src/modals/massupdate/offering_attributes/status'),
                    'payment_method': () => import('/src/modals/massupdate/offering_attributes/payment_method'),
                    'payment_status': () => import('/src/modals/massupdate/offering_attributes/payment_status'),
                    'invoice_status': () => import('/src/modals/massupdate/offering_attributes/invoice_status'),
                    'fulfilment_status': () => import('/src/modals/massupdate/offering_attributes/fulfilment_status'),
                    'shipping_status': () => import('/src/modals/massupdate/offering_attributes/shipping_status'),
                },
                'customer': {
                    'price_list_id': () => import('/src/modals/massupdate/customer_attributes/price_list_id'),
                    'status': () => import('/src/modals/massupdate/customer_attributes/status'),
                    'customerType': () => import('/src/modals/massupdate/customer_attributes/customerType'),
                    'customerGroups': () => import('/src/modals/massupdate/customer_attributes/customerGroups'),
                    'customerForm': () => import('/src/modals/massupdate/customer_attributes/customerForm'),
                    'country': () => import('/src/modals/massupdate/customer_attributes/country'),
                    'active': () => import('/src/modals/massupdate/customer_attributes/active'),
                },
            }
        }
    },
    methods: {
        ...mapGetters('MassUpdate', ['getAttributesFiltered']),
        ...mapGetters('CustomFields', ['getModelFields']),
        oldValueChanged(val) {
            this.$set(this.inputVal, 'old_value', val)
        },
        getCustomFieldOptions(name) {
            const field = this.getModelFields().find(field => {
                return 'custom.' + field.name == name
            })
            if (field) {
                if (field.options.options) {
                    return field.options.options
                }
            }
            return []
        },
    },
    mounted() {
        this.type = this.inputVal.id == 'change-attribute' ? 'offering' : 'customer'

        this.$store.dispatch('CustomFields/fetchFieldsByModel', {model: this.type})
        this.$store.dispatch('MassUpdate/fetchAttributesFiltered', {
            attribute_type: this.type,
        }).then(() => {
            this.attributesFiltered = JSON.parse(JSON.stringify(this.getAttributesFiltered()))
        })
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>